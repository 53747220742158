<template>
  <div class="prestations">
    <div class="container is-fluid">
      <page-header :entity="$EntitiesName.Prestation" :create="openCreateModal">
        <h1 class="title">Prestations</h1>
        <template #actions>
          <button v-if="!isImporting" class="button is-success" @click="isImporting = !isImporting">Importer</button>
          <button v-if="isImporting" class="button is-warning" @click="isImporting = !isImporting">Annuler</button>
        </template>
      </page-header>

      <datatable v-if="!isImporting" :fetch="$Api.Prestation.fetchPrestations" :entity="$EntitiesName.Prestation" ref="prestationList" enableDownload>
        <div slot="category" slot-scope="{ item: { category }}" title="Catégorie" :sortable="true" sortBy="category.parent.name">{{ category && category.parent.name }}</div>
        <div slot="subcategory" slot-scope="{ item: { category }}" title="Sous catégorie" :sortable="true" sortBy="category.name">{{ category && category.name }}</div>
        <div slot="name" slot-scope="{ item }" title="Nom" :sortable="true">{{ item.name }}</div>
      </datatable>

      <ImportPrestations v-if="isImporting" />
    </div>

    <modal ref="createPrestationModal" @create="createPrestation" title="Création d'une prestation">
      <div class="columns">
        <div class="column is-half">
          <ValidationObserver ref="form">
          <text-field label="Nom" v-model="prestation.name" :inline="false" :edit="true" required data-test="sel-prestation-name"></text-field>
          <many2one-field
            label="Catégorie"
            v-model="category"
            :fetch="$Api.Category.fetchParentCategories"
            reference="name"
            :entity="$EntitiesName.Category"
            :columns="categoryColumns"
            :hasFilter="true"
            :inline="false"
            :edit="true"
            required
          ></many2one-field>
          <many2one-field
            label="Sous catégorie"
            v-model="subCategory"
            :fetch="(p) => $Api.Category.fetchSubCategoriesForParent(p, category.id)"
            reference="name"
            :columns="{'parent.name': 'Parent', name: 'Nom'}"
            :inline="false"
            :hasFilter="true"
            :edit="true"
            required
            :readonly="!category.id"
            name="subcategory2one"
          >
            <span slot="helper" v-if="!category.id">Nécessite une catégorie</span>
          </many2one-field>
          </ValidationObserver>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import Datatable from '../../components/elements/Datatable';
import ImportPrestations from '../imports/Prestations'

export default {
  name: 'prestations',
  components: {
    Datatable,
    ImportPrestations,
  },
  data() {
    return {
      prestation: {},
      categoryColumns: {
        name: 'Nom',
      },
      isImporting: false
    };
  },
  computed: {
    category: {
      get() {
        if (!this.prestation.category) return {};
        return this.prestation.category.parent ? this.prestation.category.parent : this.prestation.category;
      },
      set(val) {
        if (this.prestation) { this.$set(this.prestation, 'category', val); }
      },
    },
    subCategory: {
      get() {
        if (!this.prestation.category) {
          return {};
        }
        return this.prestation.category.parent ? this.prestation.category : null;
      },
      set(val) {
        if (this.prestation.category) {
          this.prestation.category = val;
          this.prestation.category.parent = this.category;
        }
      },
    },
    openCreateModal() {
      return this.isImporting ? null : () => this.$refs.createPrestationModal.open();
    },
  },
  methods: {
    createPrestation() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        const prestation = {
          ...this.prestation,
        };

        axios.post('/prestations', prestation).then((response) => {
          if (response.data.success) {
            this.prestation = {};
            this.$refs.prestationList.refresh();
            this.$refs.createPrestationModal.close();
            this.$router.push({
              name: 'Prestation',
              params: { id: response.data.id },
            });
          }
        });
      })
    },
  },
};
</script>

<style scoped>
</style>
