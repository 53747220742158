<template> 
    <div class="panel infos">
      <div class="panel-heading has-background-primary">Copier/Coller pour importer</div>
      <div class="panel-block is-block no-border">
        <handsontable :columns="columns" :import="importPrestations"/>
      </div>
    </div>
</template>
<script>
export default {
  name: 'prestations_import',
  data: function() {
    return {
      columns: [
        {
          key: 'parentCategory',
          label: 'Catégorie',
          required: true
        },
        {
          key: 'category',
          label: 'Sous-catégorie',
          required: true
        },
        {
          key: 'prestation',
          label: 'Prestation',
          required: true
        },
      ]
    };
  },
  methods: {
    importPrestations(data) {
      return this.$Api.Import.importPrestations(data);
    }
  }
}
</script>
<style>

</style>
